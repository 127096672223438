import { defineStore } from 'pinia';

export const useFooterStore = defineStore('footer', {
	state: () => ({
		logo: {},
		footerMenu: [],
		links: {},
		copyright: '',
		additionalLinks: {},
	}),
	actions: {
		init(footer) {
			if (!footer) return;
			this.footerMenu = footer.menu;
			this.logo = {
				src: footer.logo,
				alt: footer.logoAlt,
			};
			this.copyright = footer.copyrightText;
			this.links = footer.footerLinks;
			this.additionalLinks = footer.additionalLinks;
		},
	},
});
